<style lang="less" scoped>
  @import "../../../assets/css/variables";

  .el-sel-width {
    width: 100%;
  }

  .list-table {
    .dvcimg {
      width: 100px;
      /*height: 100px;*/
    }
  }

  .productImg {
    width: 95px;
    height: 120px;
    padding: 0 10px;
    border: 1px solid #efefef;
    margin: 10px 0;
    text-align: center;
    line-height: 120px;
    color: #999;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    .img {
      width: 75px;
      height: 120px;
      margin: 0 auto;
      color: #bbb;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customer/Publicnumber/' }">
        公众号列表
      </el-breadcrumb-item>
      <el-breadcrumb-item>公众号产品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix">
      <el-table
        :data="proTypeList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" sel="" width="35"></el-table-column>
        <el-table-column label="产品图片" width="120" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.imageUrl" class="productImg">
              <div
                class="img"
                v-lazy:background-image="API_ROOT + scope.row.imageUrl"
              ></div>
            </div>
            <div v-if="!scope.row.imageUrl" class="productImg">暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="productName"
          min-width="100"
          :show-overflow-tooltip="true"
          label="品牌类型"
        ></el-table-column>
        <!-- <el-table-column prop="belongsSeries" min-width="100" :show-overflow-tooltip="true" label="品牌系列">
              </el-table-column> -->
        <el-table-column
          min-width="100"
          :show-overflow-tooltip="true"
          label="品牌型号"
        >
          <template slot-scope="scope">
            {{ scope.row.belongsType + scope.row.belongsSeries }}
          </template>
        </el-table-column>

        <el-table-column
          prop="deviceType"
          min-width="100"
          :show-overflow-tooltip="true"
          label="产品型号"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceTypeCode + scope.row.productTypeCode }}
          </template>
        </el-table-column>
        <el-table-column
          prop="customerName"
          min-width="100"
          :show-overflow-tooltip="true"
          label="品牌商"
        >
          <template slot-scope="scope">
            {{ scope.row.customerName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="remarks"
          :show-overflow-tooltip="true"
          min-width="118"
          label="备注"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          min-width="100"
          :show-overflow-tooltip="true"
          :formatter="formatTime"
          label="创建时间"
        ></el-table-column>
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <router-link
              :to="{
                path: '/device/brand/info/' + scope.row.id,
                query: { searchKey: filters.key },
              }"
            >
              <el-button
                type="primary"
                :disabled="!editPermission"
                size="small"
              >
                查看详情
              </el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>
  import {
    searchProByPublicNumber,
    searchProByCustomer,
    updateBrandType,
    createPro,
    delbrandType,
    disabledDevice,
    enabledDevice,
    dataStream,
    getAppidByCustomer,
    searchDevicesByType,
  } from "../../../services/device";
  import { updateDefaultDevice } from "../../../services/ucenter";
  import uploadDefaultImg from "../../../assets/images/upload-defualt.png";
  import { getAllCustomers, queryBrander } from "../../../services/customer";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../../config";
  import DeviceDetail from ".././detail.vue";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import { API_ROOT } from "../../../config";
  import { valiInput, valiSelect } from "../../../utils/validator/element";

  export default {
    data() {
      const valiEmpty = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("不能为空"));
        } else {
          callback();
        }
      };
      return {
        isAdmin: "",
        uploadDefaultImg,
        pinpai: "",
        loading: false, //图片上传用
        editPermission: true,
        isEdit: false,
        loaded: false,
        API_ROOT,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
          //sort: 'updateTime,desc'
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        choosedDvcTpye: {}, //新增品牌时，选中的对象
        proTypeList: [],
        filters: {
          key: "",
          customer: "",
        },
        //下面是对话框中的信息
        dvcTypeList: [],
        brandList: [],
        brandInfo: {
          customer: "",
          productName: "",
          belongsType: "",
          belongsSeries: "",
          deviceType: "",
          productId: "",
          remarks: "",
          imageUrl: "",
          appId: "",
          connectUrl: "",
          qrticket: "",
        },
        listLoading: false,
        sels: [], //列表选中列
        dialogShow: false,
        customerList: [], //品牌商列表
        productImg: uploadDefaultImg,
        qrCodeImg: uploadDefaultImg,
        appList: [],
        customerId: "",
      };
    },
    components: {
      "device-detail": DeviceDetail,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      formatTime(row) {
        return row && row.createTime
          ? moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
          : "";
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delbrandType(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryPublicTypes();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.dialogShow = true;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedDevice = Object.assign({}, row);
        this.brandInfo = {
          id: choosedDevice.id,
          publicNumberId: choosedDevice.publicNumberId,
          productName: choosedDevice.productName,
          belongsSeries: choosedDevice.belongsSeries,
          belongsType: choosedDevice.belongsType,
          deviceType: choosedDevice.deviceType,
          connectUrl: choosedDevice.connectUrl,
          appId: choosedDevice.appId,
          productId: choosedDevice.productId,
          imageUrl: choosedDevice.imageUrl,
          remarks: choosedDevice.remarks,
          qrticket: choosedDevice.qrticket,
        };
        this.pinpai = choosedDevice.customer;
      },

      /**
       * 定义序号的排序
       */
      sortDeviceByTime({ column, prop, order }) {
        if (prop === "index") {
          if (order === "ascending") {
            this.pager.sort = "updateTime,asc";
          } else {
            this.pager.sort = "updateTime,desc";
          }
          this.queryPublicTypes();
        }
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delbrandType(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryPublicTypes();
              }
            });
          })
          .catch(() => {});
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          this.queryPublicTypes();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryPublicTypes();
      },

      /**
       * 获取品牌列表
       */
      async queryPublicTypes(id) {
        this.listLoading = true;
        const res = await searchProByPublicNumber(id, this.pager);
        if (res.errorCode === 0) {
          this.proTypeList = (res && res.data && res.data.datas) || [];
          this.total = res.data.totalCount;
        }
        this.loaded = false;
        this.listLoading = false;
      },
    },

    created() {
      const id = this.$route.params.id;
      //alert(id)
      if (this.user.role.indexOf("admin") > -1) {
        this.isAdmin = true;
      }

      if (this.user.customerId) {
        this.filters.customer = this.user.customerId;
        this.brandInfo.customer = this.user.customerId;
        this.customerId = this.user.customerId;
      }
      this.queryPublicTypes(id);
    },
  };
</script>
